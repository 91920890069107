.navbar_submenu {
  padding: 0px;
  position: absolute;
  display: none;
  right: 15px;
  background-color: #f5f6f8;
  width: 85%;
  z-index: 9999;
  min-width: 160px;
  text-align: center;
  padding: 5px 0;
  li {
    list-style: none;
    a {
      color: #000;
    }
    &:hover {
      background-color: #fff;
    }
  }
}

.kt-header-menu-wrapper-close {
  width: 25px;
  height: 25px;
  top: 1px;
  z-index: 1002;
  -webkit-transition: left 0.3s ease, right 0.3s ease;
  transition: left 0.3s ease, right 0.3s ease;
  position: fixed;
  border: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
  border-radius: 3px;
  cursor: pointer;
  outline: 0 !important;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  right: 0;
}

.kt-header-menu-wrapper {
  z-index: 1001;
  position: fixed;
  -webkit-overflow-scrolling: touch;
  top: 0;
  bottom: 0;
  overflow-y: auto;
  -webkit-transform: translate3d(0, 0, 0);
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
  width: 275px !important;
  -webkit-transition: left 0.3s ease, right 0.3s ease;
  transition: left 0.3s ease, right 0.3s ease;
  left: -295px;
}
.kt-header-menu-wrapper {
  background: #fff;
  -webkit-box-shadow: 0 1px 9px -3px rgba(0, 0, 0, 0.75);
  box-shadow: 0 1px 9px -3px rgba(0, 0, 0, 0.75);
}

.kt-header-menu-wrapper.kt-header-menu-wrapper--on {
  -webkit-transition: left 0.3s ease, right 0.3s ease;
  transition: left 0.3s ease, right 0.3s ease;
  left: 0;
}

.kt-header-menu-mobile .kt-menu__nav {
  margin: 0;
  list-style: none;
  padding: 30px 0 30px 0;
}

.kt-header-menu-wrapper .kt-header-menu .kt-menu__nav {
  margin-bottom: 0;
  padding-bottom: 10px;
}

.kt-header-menu-mobile .kt-menu__nav .kt-menu__item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  float: none;
  padding: 0;
}

.kt-header-menu-mobile .kt-menu__nav .kt-menu__item > .kt-menu__heading,
.kt-header-menu-mobile .kt-menu__nav .kt-menu__item > .kt-menu__link {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  margin: 0;
  padding: 0;
  text-decoration: none;
  position: relative;
  outline: 0;
}

.kt-header-menu-mobile .kt-menu__nav > .kt-menu__item > .kt-menu__link {
  padding: 9px 30px;
  text-transform: uppercase;
  cursor: pointer;
  color: #60cdf6;
}

.kt-menu__link:hover {
  cursor: pointer;
  color: #004085;
  background-color: #f8f8fb;
}

.kt-header-menu-wrapper-overlay {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  overflow: hidden;
  z-index: 1000;
  background: rgba(0, 0, 0, 0.1);
  -webkit-animation: kt-animate-fade-in 0.3s linear 1;
  animation: kt-animate-fade-in 0.3s linear 1;
}

.kt-header-mobile__toolbar-toggler {
  display: inline-block;
  position: relative;
  overflow: hidden;
  margin: 0;
  padding: 0;
  font-size: 0;
  text-indent: -9999px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  border-radius: none;
  border: none;
  cursor: pointer;
  background: 0 0;
  outline: 0 !important;
  width: 24px;
  height: 24px;
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
  span {
    display: block;
    position: absolute;
    top: 10px;
    height: 3px;
    min-height: 3px;
    width: 100%;
    border-radius: 3px;
    -webkit-transition: all 0.4s ease;
    transition: all 0.4s ease;
    background: #fff;
    &::before {
      position: absolute;
      display: block;
      left: 0;
      width: 100%;
      height: 3px;
      min-height: 3px;
      content: "";
      border-radius: 3px;
      -webkit-transition: all 0.4s ease;
      transition: all 0.4s ease;
      background: #fff;
      top: -7px;
    }

    &::after {
      position: absolute;
      display: block;
      left: 0;
      width: 100%;
      height: 3px;
      min-height: 3px;
      content: "";
      border-radius: 3px;
      -webkit-transition: all 0.4s ease;
      transition: all 0.4s ease;
      background: #fff;
      bottom: -7px;
    }
  }
}

.kt-header-mobile__toolbar {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  height: 100%;
}

.kt-header__topbar-wrapper {
  cursor: pointer;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-right: 15px;
}

.kt-header__topbar-icon i {
  color: #fff;
}

.navbar_submenu.show {
  display: inline-block;
}

.has-submenu {
  position: relative;
  i {
    line-height: 38px;
    vertical-align: middle;
  }
  &:hover .navbar_submenu {
    display: block;
  }
  &:hover {
    cursor: pointer;
    color: #60cdf6;
  }
  @media screen and(max-width:1279px) {
    display: none;
  }
}
.is-mobile {
  display: none;
  @media screen and(max-width:1279px) {
    display: block;
  }
}
