$nav-layer: 1001;
$load-bar-layer: $nav-layer + 1;

.navbar_submenu {
  top: 46px;
  -webkit-box-shadow: 0 0 50px 0 rgba(82, 63, 105, 0.15);
  box-shadow: 0 0 50px 0 rgba(82, 63, 105, 0.15);
  background-color: #fff;
  @media (min-width: 1366px) {
    top: 35px;
  }
}

.header {
  // border-top: 2px solid white;
  @media screen and (max-width: 1279px) {
    border-top: 95px solid white;
  }
  .content {
    padding: 3em 0;
    position: relative;
    p {
      color: inherit;
      a {
        color: inherit;
        text-decoration: underline;
        font-weight: bold;
      }
    }
  }
}

.header-top {
  position: relative;
  @media screen and (max-width: 1279px) {
    position: fixed;
    top: 0px;
    z-index: $nav-layer;
    // margin: 0px;
  }
  .logo {
    height: 96px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    a {
      z-index: $nav-layer;
    }
    img {
      max-width: 100%;
      @media (min-width: 1280px) {
        max-width: 50%;
      }
    }
    &:after {
      @media (min-width: 1280px) {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        width: 0;
        height: 0;
        border-top: 0 solid transparent;
        border-right: 250px solid #fff;
        border-bottom: 96px solid transparent;
      }
    }
  }
  .navbar-desktop {
    display: block;
    @media (max-width: 1279px) {
      display: none;
    }
    background-color: #fff;
    .navbar {
      color: inherit;
      height: 100%;
      z-index: $nav-layer;
    }
    .navbar-nav {
      .menu-item {
        padding: 0 15px;
        font-size: 14px;
        a {
          text-decoration: none;
        }
      }
      .nav-link {
        padding-left: 0px;
        padding-right: 0px;
        text-transform: uppercase;
      }
    }
  }
  .navbar-mobile {
    display: none;
    @media (max-width: 1279px) {
      display: block;


    }
  }
}

.notice_1 {
  // background-color: #60cdf6;
  padding: 0.935em 1em;

  p {
    color: #3e3e3e;
    letter-spacing: 0.1px;
  }
}
.header__user--text {
  display: none;
}
.default-building {
  background-repeat: no-repeat;
  background-position: 0 100%;
  background-image: url("../static/images/sign_in/image-01.png");
  background-color: transparent;
}

.header-notice {
  &--mobile {
    position: absolute;
  }
}
@media (max-width: 1279px) {
  .process-bar {
    z-index: $load-bar-layer;
  }
  .header-notice {
    position: absolute !important;

    &--mobile {
      position: static
    }
  }
}