.dashboard {
  &__row {
    margin-bottom: 15px;
  }
}
.tooltip {
  background: rgba(255, 255, 255, 0);
}
.select-building-dashboard {
  display: inline-block;
  width: 350px;
  z-index: 1000;
  // margin-right: 15px;
  // margin-left: 15px;
  font-size: 1rem;
}
.select-standard-dashboard {
  display: inline-block;
  width: 100px;
  // margin-right: 15px;
  // margin-left: 15px;
  font-size: 1rem;
}
.list_building {
  position: absolute;
  right: 72px;
  bottom: 39px;
  background: #ededef;
  border: 2px solid #ded4d3;
  max-height: 200px;
  overflow-y: scroll;
  overflow-x: hidden;
  max-width: 317px;
  min-height: 200px;
  .tbl_building {
    min-width: 300px;
    max-width: 300px;
    tr {
      border: 2px solid #ded4d3;
      &:hover {
        cursor: pointer;
      }
    }
    td,
    th {
      padding: 5px 15px 0px 5px;
    }
  }
}
.isChoosen {
  background-color: #a3a3a5 !important;
}

.listBuildingChoosen {
  padding-top: 5px;
  padding: 15px 50px;
  span {
    margin-right: 2px;
    color: white;
  }
  .label-info {
    background-color: #5bc0de;
  }
  .tag [data-role="remove"] {
    margin-left: 8px;
    cursor: pointer;
    &:after {
      content: "X";
      padding: 0px 2px;
    }
  }
  .label {
    display: inline-block;
    padding: 0.2em 0.6em 0.3em;
    font-size: 75%;
    font-weight: 700;
    line-height: 1;
    color: #fff;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: 0.25em;
  }
}
.loadingTable {
  position: absolute;
  z-index: 5;
  background-color: rgba(255, 255, 255, 0.5);

  max-height: 200px;
  height: 200px;
  min-width: 317px;
  right: 72px;
  bottom: 39px;
  .spinner {
    margin-top: 70px;
  }
}

.standard-box {
  position: relative;
  z-index: 9999;
}
