.modal-wrapper {
  p {
    line-height: 24px;
    padding-bottom: 10px;
  }
}

.modal.show {
  display: block;
}
.toast {
  &-success {
    background: #60cdf6 !important;
    background-position: 15px center !important;
    background-repeat: no-repeat !important;
  }
  &-error {
    background: #d9534f !important;
    background-position: 15px center !important;
    background-repeat: no-repeat !important;
  }
}

.modal.show,
.modal.show .modal-dialog {
  display: flex !important;
  justify-content: center;
  align-items: center;
}
.modal.show .modal-dialog {
  height: 100%;
  margin: 0;
  width: 100%;
}

.modal-backdrop {
  opacity: 0.5 !important;
}

.modal-body {
  height: 425px;
  max-height: 80vh;
  overflow-y: auto;
}

@media (min-width: 1200px) {
  .modal-xl {
    max-width: 1140px;
  }
}
@media (min-width: 992px) {
  .modal-lg,
  .modal-xl {
    max-width: 800px;
  }
}

// .modal {
//   display: block !important; /* I added this to see the modal, you don't need this */
// }

/* Important part */
.modal-dialog {
  overflow-y: initial !important;
}

.fade {
  background: rgba(62, 62, 62, 0.3);
}
#avoid-delete-building,
#confirm-delete-mission,
#server-broken-modal,
#finish-upload-image {
  //display: none;
  position: fixed;
  width: 100%;
  height: 100%;
  background: rgba(62, 62, 62, 0.3);
  top: 0;
  left: 0;
  z-index: 9999;

  .modal-wrapper {
    width: 50%;
    background: white;
    padding: 30px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    position: absolute;
    text-align: center;
  }
}
#reportModal {
  .modal-dialog {
    max-width: 740px;
  }

  #myTabContent {
    .tab-pane {
      background-color: white;
    }
  }
}
